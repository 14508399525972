.home-box {
  section {
    margin: 0px auto;
    font-family: "Poppins", sans-serif;
    background: var(--intercalate-background-color);

    .box {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .inprogress-auction {
      .big-img-box {
        position: relative;
        span {
          font-style: italic;
          font-size: 20px;
          position: absolute;
          top: 1rem;
          left: 1rem;
          text-transform: uppercase;
        }
      }
      .text-box {
        h3 {
          font-weight: 700;
          font-size: 25px;
          color: var(--secondary-background-color);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        p {
          font-weight: 500;
          font-size: 15px;
          color: #323232;
          margin-bottom: 2rem;
        }
        button {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          border: none;
          border-radius: 10px;
          padding: 5px 15px;
          color: var(--text-color-with-secondary);
          margin-top: 2rem;
          background-color: var(--secondary-background-color);
        }
        .date-box {
          background-color: #f7f7f7;
          color: #323232;
          display: flex;
          align-items: center;
          padding: 1em;
          border-radius: 10px;
          .icon {
            width: 30px;
            height: 30px;
            background-image: url("../../assets/images/calendar.svg");
            background-size: 100%;
            background-repeat: no-repeat;
          }
          .dates {
            flex: 1;
            margin: 0 1em;
            .item {
              display: flex;
              font-size: 16px;
              font-weight: 700;
              align-items: left;
              text-transform: capitalize;
              div {
                flex: 1;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}

.background-white {
  background: white !important;
}

.background-grey {
  background: #f2f2f2 !important;
}

@media screen and (max-width: 990px) {
  .home-box {
    section {
      .inprogress-auction {
        h3 {
          margin-top: 0.5rem;
        }
        .dates {
          .item {
            display: contents !important;
          }
        }
        button {
          margin-top: 0.5rem !important;
        }
      }
    }
  }
}

.map-without-shipping {
  margin: 2rem auto;
  text-align: center;
  height: 250px;
  width: 60% !important;
  h4 {
    color: var(--primary-background-color);
  }
  @media screen and (max-width: 990px) {
    width: 100% !important;
    margin-bottom: 6rem !important;
    h4 {
      padding: 0rem 1rem !important;
    }
  }
}

#access_code.remates_bonavista {
  div:first-child {
    justify-content: center;
  }

  .white-background {
    background: white;
    padding-left: 0rem;
  }

  img {
    height: 100%;
    object-fit: cover;
  }

  span.text-black {
    padding: 4rem 0rem;
  }
}
