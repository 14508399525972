.unique-menu {
  width: 100%;
  background: var(--primary-background-color) !important;
  height: 90px;
  position: relative;
  z-index: 2;
  top: 0px;
  -webkit-box-shadow: 0 7px 30px -6px #abbed1;
  -moz-box-shadow: 0 7px 30px -6px #abbed1;
  box-shadow: 0 7px 30px -6px #abbed1;
  font-size: 16px;

  .box-items {
    padding: 15px 0px;

    .link {
      .item {
        text-align: center;
      }
    }
    .start-items {
      flex: 1;
      display: flex;
      a {
        margin: 0px;
      }
      .logo {
        height: 40px;
      }
    }
    .end-items {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item {
        margin-right: 0px;
        .link {
          width: 110px;
        }
      }
    }
    a {
      color: var(--text-color-with-primary);
    }
    .a-hello {
      margin-right: 2rem;
    }
    .button-hello {
      justify-content: end;
    }

    img {
      height: 50px;
      width: auto;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 110px;
    padding-top: 10px;
    .box-items {
      padding-top: 5px;
      .a-hello {
        margin-right: 0rem;
      }
      .button-hello {
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  /* el primer hijo div de container */
  .unique-menu {
    .mobile {
      max-height: 50px;
      img {
        max-height: 50px;
      }
    }

    .container > div:nth-child(3) {
      width: auto !important;
      margin-right: auto;
    }

    .container > button {
      margin-top: 3px;
    }
  }
}
