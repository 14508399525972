.pagination-rounded {
  ul {
    justify-content: center;
  }

  .page-item {
    cursor: pointer;
  }

  .page-item.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
    text-align: center;
    font-size: 11px;
    color: var(--primary-background-color) !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem;
  }

  .page-item.active .page-link,
  .page-link:hover {
    background-color: var(--primary-background-color) !important;
    border-color: var(--primary-background-color) !important;
    color: var(--secondary-background-color) !important;
  }
}
